import React from 'react'
import MomentUtils from '@date-io/moment'
import {Grid, FormGroup, RadioGroup, Checkbox, Radio, FormControlLabel, Slider, FormLabel, InputAdornment, makeStyles, Theme} from '@material-ui/core'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Mark } from '@material-ui/core/Slider'
import { ValidationKeyboardDatePicker, ValidationTextField } from 'StyledComponents/ValidationFields'
import RadioOption from 'dskcore/@interfaces/RadioOption'

import * as Styled from 'StyledComponents/Signup'

interface TextOptions {
  autoFocus?: boolean,
  autoComplete?: string,
  valid: number,
  error: boolean,
  helperText: string,
  multiline?: boolean,
  rows?: number,
  rowsMax?: number,
  type?: 'text' | 'password',
  inputProps?: Object,
  onBlur?: Function,
  onFocus?: Function
}

export interface RangeOptions {
  valid: number,
  error: boolean,
  helperText: string,
  min: number,
  max: number,
  marks: Mark[],
  step?: number,
  inputMultiplier?: number
}

interface RadioOptions {
  valid: number,
  error: boolean,
  helperText: string,
  options: RadioOption[],
  disabled?: boolean,
  required?: boolean
}

interface CheckboxesOptions extends RadioOptions {
}

interface NumberOptions {
  valid: number,
  error: boolean,
  helperText: string,
  icon: JSX.Element,
  min?: number,
  max?: number,
  endAdornment?: JSX.Element
}

interface DatepickerOptions {
  valid: number,
  error: boolean,
  helperText: string,
  disableFuture?: boolean,
  disablePast?: boolean,
  minDate?: Date,
  maxDate?: Date,
  disabled?: boolean
}

interface RenderInputProps {
  type: 'text' | 'range' | 'slider' | 'radio' | 'checkbox' | 'checkboxes' | 'number' | 'datepicker',
  name: string,
  label?: string
}

const RenderInputStyles = makeStyles((theme: Theme) => ({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  label: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#373F41',
    lineHeight: '18px'
  }
}))

// Generates an input field for Question
const RenderInput = ({input, typeOptions, value, handleChange}: {input: RenderInputProps, typeOptions?: any, value: any, handleChange: Function}) => {
  const classes = RenderInputStyles()

  switch (input.type) {
    case 'range':
      const rangeOptions: RangeOptions = typeOptions 
      const multiplier = rangeOptions && rangeOptions.inputMultiplier ? rangeOptions.inputMultiplier : 1
      const step = rangeOptions && rangeOptions.step ? rangeOptions.step : 1
      const handleRangeChange = (newValue: number | number[]) => {
        handleChange(input.name, newValue)
      }

      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isMin: boolean) => {
        handleRangeChange(event.target.value === '' ? [] : isMin ?
          [Number(event.target.value) / multiplier, value[1]] :
          [value[0], Number(event.target.value) / multiplier]
        )
      }

      const handleBlur = () => {
        if (value[0] > value[1]) { // min is larger than max value, reset min
          handleRangeChange([rangeOptions.min, value[1]])
        } else if (value[0] > value[1]) { // max is smaller than min, reset max
          handleRangeChange([value[0], rangeOptions.max])
        } else if (value[0] < rangeOptions.min) { // if lower than possible reset min
          handleRangeChange([rangeOptions.min, value[1]])
        } else if (value[1] > rangeOptions.max) { // if higher than possible reset max
          handleRangeChange([value[0], rangeOptions.max])
        }
      }

      return <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs>
          <Slider
            min={rangeOptions.min}
            max={rangeOptions.max}
            marks={rangeOptions.marks}
            onChange={(e, newValue) => handleRangeChange(newValue)}
            value={value}
          />
        </Grid>
        <Grid item>
          <ValidationTextField
            name={input.name + 'Left'}
            value={value[0] * multiplier}
            margin='dense'
            className='min50'
            valid={rangeOptions.valid}
            error={rangeOptions.error}
            onChange={e => handleInputChange(e, true)}
            onBlur={handleBlur}
            inputProps={{
              step,
              min: rangeOptions.min * multiplier,
              max: rangeOptions.max * multiplier,
              type: 'number'
            }} />
        </Grid>
        <Grid item>
          <ValidationTextField
            name={input.name + 'Right'}
            value={value[1] * multiplier}
            margin='dense'
            className='min50'
            valid={rangeOptions.valid}
            error={rangeOptions.error}
            onChange={e => handleInputChange(e, false)}
            onBlur={handleBlur}
            inputProps={{
              step,
              min: rangeOptions.min * multiplier,
              max: rangeOptions.max * multiplier,
              type: 'number'
            }} />
          </Grid>
        </Grid>
        {rangeOptions.error && <Styled.Label
          className={`${classes.label} mb-2`}
          error={rangeOptions.error ? 1 : 0}>{rangeOptions.helperText}</Styled.Label>}
      </React.Fragment>
    case 'slider':
      const sliderOptions: RangeOptions = typeOptions 

      const handleSliderChange = (newValue: number | number[]) => {
        handleChange(input.name, newValue)
      }

      const handleSliderInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSliderChange(event.target.value === '' ? 5 : Number(event.target.value))
      }
    
      const handleSliderBlur = () => {
        if (value < sliderOptions.min) {
          handleSliderChange(sliderOptions.min)
        } else if (value > sliderOptions.max) {
          handleSliderChange(sliderOptions.max)
        }
      }

      return <Grid container spacing={2}>
        <Grid item xs>
          <Slider
            step={sliderOptions.step}
            min={sliderOptions.min}
            max={sliderOptions.max}
            marks={sliderOptions.marks}
            onChange={(e, newValue) => handleSliderChange(newValue)}
            value={value}
          />
        </Grid>
        <Grid item>
          <ValidationTextField
            name={input.name}
            value={value}
            className='min50'
            margin='dense'
            valid={sliderOptions.valid}
            error={sliderOptions.error}
            onChange={handleSliderInputChange}
            onBlur={handleSliderBlur}
            inputProps={{
              step: sliderOptions.step || 1,
              min: sliderOptions.min,
              max: sliderOptions.max,
              type: 'number'
            }} />
        </Grid>
      </Grid>
    case 'text':
      const textOptions: TextOptions = typeOptions 

      const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleChange(input.name, event.target.value, event.target.selectionStart)
      }

      return <ValidationTextField
        name={input.name}
        label={input.label}
        value={value}
        onChange={handleTextChange}
        valid={textOptions.valid}
        error={textOptions.error}
        helperText={textOptions.helperText}
        autoComplete={textOptions.autoComplete}
        multiline={textOptions.multiline}
        rows={textOptions.rows}
        rowsMax={textOptions.rowsMax}
        autoFocus={textOptions.autoFocus}
        type={typeOptions.type}
        placeholder={typeOptions.placeholder}
        InputProps={typeOptions.InputProps}
        required={typeOptions.required}
        onBlur={typeOptions.onBlur}
        onFocus={typeOptions.onFocus}
        fullWidth
      />
    case 'number': 
      const numberOptions: NumberOptions = typeOptions
      
      const min = numberOptions && numberOptions.min ? numberOptions.min : 0
      const max = numberOptions && numberOptions.max ? numberOptions.max : 100

      return <Grid container spacing={1}>
        {numberOptions && numberOptions.icon && <Grid item className='mt-3'>
          <div className='mt-1'>{numberOptions.icon}</div>
        </Grid>}
        <Grid item className='flex-one'>
          <ValidationTextField
            name={input.name}
            type='number'
            value={value}
            label={input.label}
            onChange={e => handleChange(input.name, e.target.value)}
            inputProps={{
              step: 1,
              min,
              max,
              type: 'number'
            }}
            InputProps={{
              endAdornment: numberOptions.endAdornment && <InputAdornment position='end'>{numberOptions.endAdornment}</InputAdornment>
            }}
            InputLabelProps={{
              shrink: true
            }}
            valid={numberOptions.valid}
            error={numberOptions.error}
            helperText={numberOptions.helperText}
            fullWidth
            required />
        </Grid>
      </Grid>
    case 'radio':
      const radioOptions: RadioOptions = typeOptions 

      return <Styled.FormControlStyled className='radio-horizontal'>
        <FormLabel>
          <Styled.Label
            className={classes.label}
            valid={radioOptions.valid}
            error={radioOptions.error ? 1 : 0} gutterBottom>
            {input.label}
          </Styled.Label>
        </FormLabel>
        <RadioGroup
          value={value}
          onChange={e => handleChange(input.name, e.target.value)}>
          {radioOptions.options.map((option: RadioOption) => <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color='primary' />}
            label={option.label}
            disabled={radioOptions.disabled} />)}
        </RadioGroup>
        {radioOptions.error && <Styled.Label error={radioOptions.error ? 1  :0} gutterBottom>
          {radioOptions.helperText}
        </Styled.Label>}
      </Styled.FormControlStyled>

    case 'checkbox':
      return <FormGroup row>
        <FormControlLabel
          label={input.label}
          control={<Checkbox
            color='primary'
            checked={value}
            onChange={e => handleChange(input.name, e.target.checked)} />}
        />
      </FormGroup>
    case 'checkboxes':
      const checkboxesOptions: CheckboxesOptions = typeOptions

      const handleCheckboxesChange = (name: string, checkboxValue: any) => {
        let newValue = value
        if (checkboxValue === true) {
          newValue = value.concat(name)
        } else if (checkboxValue === false) {
          newValue = value.filter((option: string) => option !== name)
        }
        handleChange(input.name, newValue)
      }

      return <Styled.FormControlStyled className='radio-horizontal'>
        <FormLabel>
          <Styled.Label
            className={classes.label}
            valid={checkboxesOptions.valid}
            error={checkboxesOptions.error ? 1 : 0}>
            {`${input.label} ${checkboxesOptions.required ? '*' : ''}`} 
          </Styled.Label>
        </FormLabel>

        <FormGroup>
          {checkboxesOptions.options.map((option: RadioOption) => <FormControlLabel
            key={option.value}
            label={option.label}
            control={<Checkbox
              color='primary'
              checked={value.includes(option.value)}
              onChange={e => handleCheckboxesChange(option.value, e.target.checked)} />}
          />)}
          {checkboxesOptions.error && <Styled.Label error={checkboxesOptions.error ? 1 : 0} gutterBottom>
            {checkboxesOptions.helperText}
          </Styled.Label>}
        </FormGroup>
      </Styled.FormControlStyled>
    case 'datepicker':
      const datepickerOptions: DatepickerOptions = typeOptions

      return <MuiPickersUtilsProvider utils={MomentUtils}>
        <ValidationKeyboardDatePicker
          name={input.name}
          valid={datepickerOptions.valid}
          error={datepickerOptions.error}
          helperText={datepickerOptions.helperText}
          disableFuture={datepickerOptions.disableFuture}
          disablePast={datepickerOptions.disablePast}
          minDate={datepickerOptions.minDate}
          maxDate={datepickerOptions.maxDate}
          disabled={datepickerOptions.disabled}
          className={'mt-3 w-100'}
          openTo='year'
          variant='inline'
          format='DD/MM/YYYY'
          views={['year', 'month', 'date']}
          value={value}
          label={input.label}
          onChange={date => handleChange(input.name, date ? date.toISOString() : null)}
          required
        />
      </MuiPickersUtilsProvider>
    default:
      return <span>{input.type} does not exist.<br/></span>
  }
}

export default RenderInput