import { gql } from '@apollo/client'

export const LOGIN_ADMIN = gql`
  mutation loginAdmin($username: String!, $password: String!) {
    loginAdmin(username: $username, password: $password) {
      _id
      username
      password
      isAdmin
      accessToken
      refreshToken
      passwordResetToken
      passwordResetToken
      updatedAt
      createdAt
    }
  }
`