import { gql } from '@apollo/client'

export const GET_STAGE_LOCATIONS = gql`
  query getStageLocations(
    $disableFilter: Boolean!,
    $praktijkType: String!,
    $voorkeurStudent: String!
  ) {
    stageLocations(
      praktijkType: $praktijkType
      voorkeurStudent: $voorkeurStudent
      disableFilter: $disableFilter
    ) {
      stageId
      markerId
      address
      coordinates
    }
  }
`