import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useQuery, useMutation } from '@apollo/client'
import { Container, Grid, Tooltip } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Loading from 'Components/Loading'
import Snackbar from 'Components/Snackbar'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { LIST_STAGES } from 'Apollo/Remote/Admin/LIST_STAGES'
import { DELETE_STAGE } from 'Apollo/Remote/Admin/DELETE_STAGE'
import { ApplicationState } from 'Store/Reducers/reducers'
import Stage from 'dskcore/@interfaces/Stage'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import { setFilter } from 'Store/Actions/filter-actions'

const useStyles = makeStyles((theme: Theme) => ({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  gridHeader: {
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#000',
    letterSpacing: '0.1px',
    lineHeight: '40px',
  },
  form: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  label: {
    fontFamily: '"Mulish", sans-serif',
    color: '#373F41',
    fontWeight: 600,
    fontSize: '14px',
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    flex: 1,
    minWidth: '300px',
    marginLeft: '30px'
  },
  buttonColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  newStage: {
    textTransform: 'initial',
    height: '40px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold',
    width: '150px'
  },
  table: {
    minWidth: 650,
  },
  succesRow: {
    background: '#ccc !important'
  },
  oddRow: {
    background: '#E5E5E5'
  },
  tableIcon: {
    textAlign: 'center',
    fontSize: '14px',
    color: theme.palette.primary.main
  },
  tableHeader: {
    color: '#373F41'
  },
  praktijk: {
    color: '#222222'
  },
}))

const AdminStagesScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const createdStageId = new URL(window.location.href).searchParams.get('created')

  const [goToNewStage, setGoToNewStage] = React.useState<boolean>(false)
  const [editStageId, setEditStageId] = React.useState<string>()
  const [stages, setStages] = React.useState<Stage[]>([])
  const [stagesPerPage, setStagesPerPage] = React.useState<number>(25)
  const [stageCount, setStageCount] = React.useState<number>(0)
  const [closedSuccess, setClosedSuccess] = React.useState<boolean>(false)
  
  const filter: FilterStageObject = useSelector((state: ApplicationState) => state.filter)
  const _setFilter: Function = useCallback((filter: FilterStageObject) => dispatch(setFilter(filter)), [dispatch])

  const [_searchString, _setSearchString] = React.useState<string | undefined>(filter.searchString)

  const setSearchString = useDebouncedCallback((searchString: string) => {
    _setFilter({
      ...filter,
      searchString,
      page: 0
    })
  }, 250)

  const onChangeSearchString = (searchString: string) => {
    _setSearchString(searchString)
    setSearchString.callback(searchString)
  }

  const onChangePage = (event: unknown, page: number) => {
    _setFilter({
      ...filter,
      page
    })
    const titleElement = document.getElementById('scrollContainer')
    if (titleElement) {
      titleElement.scrollTo(0,0)
    }
  }

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStagesPerPage(parseInt(event.target.value, 10));
    _setFilter({
      ...filter,
      page: 0
    })
  }

  const listStagesVariables = {
    page: filter.page,
    perPage: stagesPerPage,
    disableFilter: true,
    praktijk: filter.searchString
  }

  const { loading, error, data } = useQuery(LIST_STAGES, {
    variables: listStagesVariables,
    fetchPolicy: 'cache-and-network'
  })

  const [deleteStage] = useMutation(DELETE_STAGE, {
    refetchQueries: [{
      query: LIST_STAGES,
      variables: listStagesVariables
    }],
    errorPolicy: 'all'
  })

  React.useEffect(() => {
    if (data && data.stagePaginationFiltering) {
      setStages(data.stagePaginationFiltering.stages)
      setStageCount(data.stagePaginationFiltering.count)
    }
  }, [data])

  const closeSnackbar = (): void => {
    setClosedSuccess(true)
    window.history.pushState({}, document.title, '/admin/stages')
  }

  const _deleteStage = (stageId: string) => {
    const stage = stages.filter((stage: Stage) => stage._id === stageId)[0]
    const confirmDeletion = window.confirm(`Wil je "${stage.praktijk}" definitief verwijderen?`)
    if (confirmDeletion === true) {
      deleteStage({
        variables: {
          _id: stageId
        }
      })
      closeSnackbar()
    }
  }

  if (error) return <p>ERROR</p>
  if (goToNewStage) return <Redirect to='/admin/stage' push />
  if (editStageId) return <Redirect to={`/admin/stage/${editStageId}`} push />

  return <Container component='main' maxWidth='lg' className='mt-3 pt-1 pb-5'>
    {createdStageId && !closedSuccess && <Snackbar
      variant='success'
      className='mb-4'
      message={'Stage opgeslagen'}
      onClose={() => closeSnackbar()} />}

    <Grid container spacing={3} className={classes.gridHeader}>
      <Grid item md={5} xs={12}>
        <Typography component='h1' variant='h5' className={`${classes.font} ${classes.title} pt-4 pb-4`}>
          Dashboard
        </Typography>
      </Grid>
      <Grid item md={5} xs={12}>
        <form className={classes.form} noValidate>
          <Typography variant='body1' className={classes.label}>Zoek op naam</Typography>
          <TextField
            className={classes.input}
            value={_searchString}
            placeholder='Bijv. Dierenkliniek Middenwaard'
            label='Praktijk'
            variant='outlined'
            size='small'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeSearchString(e.target.value)} />
        </form>
      </Grid>
      <Grid item md={2} xs={12} className={classes.buttonColumn}>
        <Button
          id='loginsubmit'
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.newStage}
          onClick={() => setGoToNewStage(true)}
          startIcon={<AddIcon />}>
          Nieuwe stage
        </Button>
      </Grid>
    </Grid>

    {loading === true ? <Loading/> : <TableContainer component={Paper}>
      <Table className={classes.table} size='small'>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.font} ${classes.tableHeader}`}>Praktijk</TableCell>
            <TableCell className={`${classes.font} ${classes.tableHeader}`} align='right'>Bewerken</TableCell>
            <TableCell className={`${classes.font} ${classes.tableHeader}`} align='right'>Verwijderen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stages.map((stage: Stage, index: number) => {
            return <TableRow key={stage._id} className={`${stage._id === createdStageId ? classes.succesRow : ''} ${index % 2 === 0 ? classes.oddRow : ''}`}>
              <TableCell className={`${classes.font} ${classes.praktijk} capitalize`} component='th' scope='row'>
                <Tooltip title={stage.locations.map((stageLocation: StageLocation, index: number) => {
                  return stageLocation.address + (index === stage.locations.length - 1 ? ' ': ' | ')
                })}>
                  <span>{stage.praktijk}</span>
                </Tooltip>
              </TableCell>
              <TableCell align='right' className={classes.tableIcon}>
                <EditIcon className='pointer' onClick={() => setEditStageId(stage._id)} />
              </TableCell>
              <TableCell align='right' className={classes.tableIcon}>
                <DeleteIcon className='pointer' onClick={() => _deleteStage(stage._id)} />
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={stageCount}
        rowsPerPage={stagesPerPage}
        page={filter.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableContainer>}
  </Container>
}

export default AdminStagesScreen