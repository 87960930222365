import { combineReducers, Reducer } from 'redux'

import User from 'dskcore/@interfaces/User'
import Disclaimer from 'dskcore/@types/Disclaimer'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'

import { userReducer } from './user-reducer'
import { disclaimerReducer } from './disclaimer-reducer'
import { filterReducer } from './filter-reducer'

// Top level application state
export interface ApplicationState {
  user: User | null,
  disclaimer: Disclaimer,
  filter: FilterStageObject
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  user: userReducer,
  disclaimer: disclaimerReducer,
  filter: filterReducer
})