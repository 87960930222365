import React, {useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'

import { ApplicationState } from 'Store/Reducers/reducers'
import DisclaimerAlert from 'Components/DisclaimerAlert'
import { setDisclaimer } from 'Store/Actions/disclaimer-actions'

import Disclaimer from 'dskcore/@types/Disclaimer'

const useStyles = makeStyles({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  }
})

const DisclaimerScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const disclaimer: Disclaimer | null = useSelector((state: ApplicationState) => state.disclaimer)
  const _setDisclaimer: Function = useCallback((disclaimer: Disclaimer) => dispatch(setDisclaimer(disclaimer)), [dispatch])

  return <Container component='main' maxWidth='lg' className='mt-5 pt-1 pb-5'>
    <Grid container spacing={3} justify='center'>
      <Grid item sm={6} sm-offset={3} xs={12}>
        {disclaimer === null ? <DisclaimerAlert checked={!!disclaimer} handleChange={(event: React.ChangeEvent<HTMLInputElement>) => _setDisclaimer(event?.target.checked)} /> : (disclaimer === false ? <>
          <Breadcrumbs aria-label='breadcrumb' className={`${classes.font} mb-4`}>
            <Link color='inherit' href='#' onClick={() => _setDisclaimer(null)} className={`${classes.font}`}>
              Terug naar de voorwaarden
            </Link>
          </Breadcrumbs>
          <span>
            U bent niet akkoord gegaan met de algemene voorwaarden, het privacybeleid van de D.S.K. en het juridische beleid van VetWise. Daarom kan u geen gebruik kan maken van de website.<br />
          </span>
        </> : <Redirect to='/stages' />)}
      </Grid>
    </Grid>
  </Container>
}

export default DisclaimerScreen