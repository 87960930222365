import Disclaimer from 'dskcore/@types/Disclaimer'
import { SET_DISCLAIMER_ACTION } from './disclaimer-actions-types.d'

/*
 * Disclaimer actions
 */
export const SET_DISCLAIMER = 'SET_DISCLAIMER'

export const setDisclaimer = (disclaimer: Disclaimer): SET_DISCLAIMER_ACTION => ({
  type: SET_DISCLAIMER,
  payload: {
    disclaimer
  }
})