import React from 'react'
// import Toolbar from '@material-ui/core/Toolbar'
// import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
// import Button from '@material-ui/core/Button'
import RenderInput from 'Components/RenderInput'
import MapSelectCoordinates from 'Components/MapSelectCoordinates'

// import * as Styled from 'StyledComponents/Navbar'
import StageLocation from 'dskcore/@interfaces/StageLocation'

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 400,
    color: '#000',
    letterSpacing: '0.1px',
    lineHeight: '22.6px'
  },
  title: {
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold',
    color: '#55357B',
    fontSize: '13px',
    lineHeight: '13px',
  },
  modalBody: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    padding: '20px',
    outline: 0
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'initial',
    height: '40px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold'
  },
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
    fontSize: '14px',
    letterSpacing: '0.1px',
  },
  infoContainer: {
    maxWidth: '92%'
  },
}))

const ModalAddressEdit = ({
  stageLocation,
  updateStageLocation,
  openModalAddressEdit,
  setOpenModalAddressEdit
}: {
  stageLocation: StageLocation,
  updateStageLocation: Function,
  openModalAddressEdit: boolean,
  setOpenModalAddressEdit: Function
}) => {
  const classes = useStyles()

  const [modalStyle] = React.useState(getModalStyle)
  const [address, setAddress] = React.useState<string>(stageLocation.address)
  const [coordinates, setCoordinates] = React.useState<L.LatLngExpression>(stageLocation.coordinates)

  const updateAddress = (name: string, value: any): void => setAddress(value)

  const updateCoordinates = (coordinates: L.LatLngLiteral) => {
    console.log([coordinates.lat, coordinates.lng])
    setCoordinates([coordinates.lat, coordinates.lng])
  }
  
  const onSubmit = () => {
    if (!address || address.length < 0) {
      return alert('Adres ontbreekt')
    }

    const newStageLocation: StageLocation = {
      ...stageLocation,
      address,
      coordinates
    }

    updateStageLocation(newStageLocation)
  }

  return <Modal
    open={openModalAddressEdit}
    onClose={() => setOpenModalAddressEdit(false)}>

    <div style={modalStyle} className={`${classes.modalBody} sm-modal`}>
      <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
        Wijzig adres
      </Typography>
      <Alert className={`${classes.font} ${classes.infoContainer} mb-4`} severity='info'>
        Indien het adres niet gevonden kan worden, kun je hier het adres invullen en de marker op de juiste plek zetten.
      </Alert>
      <RenderInput input={{
        type: 'text',
        name: 'address',
        label: 'Adres'
      }}
      value={address}
      handleChange={updateAddress}
      typeOptions={{}} />
      <div className='mb-3'/>
      <MapSelectCoordinates
        stageLocation={stageLocation}
        updateCoordinates={updateCoordinates} />
      <div className='mb-3'/>
      <Button
        type='submit'
        id='modalAddressEditButton'
        variant='contained'
        color='primary'
        className={`${classes.submit} mt-2`}
        startIcon={<CheckIcon />}
        onClick={onSubmit}>
        Bevestigen
      </Button>
    </div>
  </Modal>
}

export default ModalAddressEdit