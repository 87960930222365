import User from 'dskcore/@interfaces/User'
import { SET_USER_ACTION, LOG_OUT_ACTION } from './user-actions-types.d'

/*
 * User actions
 */
export const SET_USER = 'SET_USER'
export const LOG_OUT = 'LOG_OUT'

export const setUser = (user: User): SET_USER_ACTION => ({
  type: SET_USER,
  payload: {
    user
  }
})

export const logOut = (): LOG_OUT_ACTION => ({
  type: LOG_OUT
})