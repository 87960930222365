import React from 'react'
// import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Container } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'

import StageEdit from 'Components/StageEdit'

import { initialStage, getEmptyAddress } from 'Store/store'
import { ApplicationState } from 'Store/Reducers/reducers'
import { CREATE_STAGE } from 'Apollo/Remote/Admin/CREATE_STAGE'
import { GET_STAGE_LOCATIONS } from 'Apollo/Remote/Stage/GET_STAGE_LOCATIONS'
import { Redirect } from 'react-router-dom'

const CreateStage = () => {
  const [stage, setStage] = React.useState<Stage>(initialStage)
  const [stageId, setStageId] = React.useState<string>('')
  const [stageLocations, setStageLocations] = React.useState<StageLocation[]>([getEmptyAddress()])
  const [success, setSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const filter: FilterStageObject = useSelector((state: ApplicationState) => state.filter)

  const [createStage, { data, loading, error: _error }] = useMutation(CREATE_STAGE, {
    refetchQueries: [{
      query: GET_STAGE_LOCATIONS,
      variables: {
        praktijkType: filter.praktijkType,
        voorkeurStudent: filter.voorkeurStudent,
        disableFilter: filter.disableFilter
      }
    }],
    errorPolicy: 'all'
  })

  React.useEffect(() => {
    if (data?.createStage?._id) {
      const titleElement = document.getElementById('scrollContainer')
      if (titleElement) {
        titleElement.scrollTo(0,0)
      }
      setSuccess(true)
      setError('')
      setStageId(data.createStage._id)
    }
  }, [data])

 
  React.useEffect(() => {
    if (_error) {
      setSuccess(false)
      if (_error.graphQLErrors[0].extensions) {
        const errorsObject = _error.graphQLErrors[0].extensions.exception
        setError(errorsObject)
      }
    }
  }, [_error])


  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    console.log(stageLocations)
    createStage({
      variables: {
        ...stage,
        locations: stageLocations
      }
    })
  }

  if (stageId) {
    return <Redirect to={`/admin/stages?created=${stageId}`} />
  }

  return <Container component='main' maxWidth='lg' className='mt-3 pb-5'>
    <StageEdit
      stage={stage}
      setStage={setStage}
      stageLocations={stageLocations}
      setStageLocations={setStageLocations}
      onSubmit={onSubmit}
      success={success}
      setSuccess={setSuccess}
      error={error}
      setError={setError}
      isEdit={false}
      loading={loading} />
  </Container>
}

export default CreateStage