import { gql } from '@apollo/client'

export const LIST_STAGES = gql`
  query getStages(
    $page: Int!,
    $perPage: Int!,
    $disableFilter: Boolean!
    $praktijk: String
  ) {
    stagePaginationFiltering(
      page: $page
      perPage: $perPage,
      disableFilter: $disableFilter,
      praktijk: $praktijk
    ) {
      count
      stages { 
        _id
        praktijk
        locations {
          markerId
          address
        }
        createdAt
      }
    }
  }
`