import React from 'react'
import L from 'leaflet'
import { TileLayer } from 'react-leaflet'

import { DEFAULT_CENTER } from 'Helpers/MapHelpers'
import { MapLocation } from '../StyledComponents/Map'

// Use of Map can be specified for onMapClick
const Map = ({
  center = DEFAULT_CENTER,
  bounds,
  zoom = 14,
  updatePosition,
  onMapClick,
  children
}: {
  center?: L.LatLngExpression,
  bounds?: L.LatLngBounds,
  zoom?: number,
  updatePosition?: Function,
  onMapClick?: any,
  children?: React.ReactElement
}) => {
  const _onMapClick = onMapClick ? onMapClick : (event: any) => {
    if (updatePosition) {
      updatePosition(null, event.latlng)
    }
  }

  const _bounds = bounds && Object.keys(bounds).length > 0 ? bounds : null

  // @ts-ignore all
  return <MapLocation
    center={center}
    bounds={_bounds}
    zoom={zoom}
    onclick={_onMapClick}
    maxZoom={17}
    isAdmin={!!onMapClick}>
    <TileLayer
      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    />
    {children}
  </MapLocation>
}

export default Map