import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import PraktijkType from 'dskcore/@types/PraktijkType'

const useStyles = makeStyles({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
    fontSize: '14px',
    letterSpacing: '0.1px',
  },
  praktijk: {
    fontFamily: '"Mulish", sans-serif',
    color: '#222',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '14px'
  },
  infoContainer: {
    fontSize: '14px'
  },
  infoDescriptor: {
    fontWeight: 600
  },
  highlightedInfo: {
    color: '#3C64B1',
    fontWeight: 500,
    fontSize: '14px'
  },
  addressContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  address: {
    color: '#3C64B1'
  },
  mailAlert: {
    width: 'calc(100% - 30px)'
  }
})

const capitalize = (text: string) => text.slice(0,1).toUpperCase() + text.slice(1)

const StageTextComponent = ({stage, showDetail}: {stage: Stage, showDetail: boolean}) => {
  const classes = useStyles()

  const stageduur = stage.stageduur || '-'
  const stagedagen = stage.stagedagen || '-'
  const contactpersoon = stage.contactpersoon || '-'
  const telefoonnummerFormatted = stage.telefoonnummer || '-'

  const voorkeurStudent = stage.voorkeurStudent
  const voorkeurStudentFormatted = voorkeurStudent === 'Geen voorkeur' ? 'Geen voorkeur voor Bachelor/Master' : voorkeurStudent
  
  const hasMailcontactpersoon = stage.mailcontactpersoon && stage.mailcontactpersoon.length > 0
  const hasMail = stage.mail && stage.mail.length > 0
  const mailCC = 'vetwise.dsk@gmail.com'
  const mailSubject = `Stage bij ${stage.praktijk}`

  const website = stage.website
  const hasWebsite = stage.website && stage.website.length > 0
  const websiteFormatted = hasWebsite ? website : '-'

  return <>
    <Typography variant='h5' className={`${classes.praktijk} mb-1 capitalize`}>
      {stage.praktijk}
    </Typography>
    <Typography variant='subtitle1' className={`${classes.font} ${classes.subtitle} mb-2`}>
      {stage.praktijkType.map((praktijkType: PraktijkType, index: number) => {
        return praktijkType + (index !== stage.praktijkType.length - 1 ? ', ' : '')
      })} | {voorkeurStudentFormatted}
    </Typography>

    <Typography variant='body1' className={`${classes.font} ${classes.infoContainer}`}>
      {stage.locations.map((stageLocation: StageLocation) => {
        return <span key={stageLocation.markerId} className={classes.addressContainer}>
          <RoomOutlinedIcon htmlColor='#3C64B1'/>
          <span key={stageLocation.markerId} className={classes.address}>
            {stageLocation.address}
          </span>{' '}
        </span>
      })}
    </Typography>

    {stage.beschrijving ? <Typography variant='body1' className={`${classes.font} mt-2 pr-3`}>
      {!showDetail ? (stage.beschrijving.length > 150 ? (capitalize(stage.beschrijving).slice(0,150).trim() + '...') : stage.beschrijving) : capitalize(stage.beschrijving)}
    </Typography> : null}

    {showDetail ? <>
      <Typography variant='body1' className={`${classes.font} ${classes.infoContainer} mt-4`}>
        <span className={classes.infoDescriptor}>Gewenste stage duur:</span> {stageduur}
      </Typography>

      <Typography variant='body1' className={`${classes.font} ${classes.infoContainer}`}>
        <span className={classes.infoDescriptor}>Mogelijke stage dagen:</span>{' '}
        {stagedagen}
      </Typography>

      <Typography variant='body1' className={`${classes.font} ${classes.infoContainer} mt-4`}>
        <span className={classes.infoDescriptor}>Contactpersoon:</span>{' '}
        {contactpersoon}
      </Typography>

      <Typography variant='h6' className={`${classes.font}`}>
        <span className={classes.infoDescriptor}>Mail contactpersoon:</span>{' '}
        {hasMailcontactpersoon ? <a href={`mailto:${stage.mailcontactpersoon}?cc=${mailCC}&subject=${mailSubject}`} className={classes.highlightedInfo}>
          {stage.mailcontactpersoon}
        </a> : '-'}
      </Typography>

      <Typography variant='h6' className={`${classes.font} mt-4`}>
        <span className={classes.infoDescriptor}>Mail:</span>{' '}
        {hasMail ? <a href={`mailto:${stage.mail}?cc=${mailCC}&subject=${mailSubject}`} className={classes.highlightedInfo}>
          {stage.mail}
        </a> : '-'}
      </Typography>

      <Typography variant='h6' className={`${classes.font}`}>
        <span className={classes.infoDescriptor}>Website:</span>{' '}
        {hasWebsite ? <a href={website} target='_blank' rel='noopener noreferrer' className={classes.highlightedInfo}>
          {websiteFormatted}
        </a> : websiteFormatted}
      </Typography>

      <Typography variant='h6' className={`${classes.font}`}>
        <span className={classes.infoDescriptor}>Telefoonnummer:</span>{' '}
        {telefoonnummerFormatted}
      </Typography>

      {hasMail ? <Alert severity='info' className={`${classes.mailAlert} ${classes.font} mt-3`}>Mailen? Zet dan vetwise.dsk@gmail.com in de CC, zodat wij de stagebank kunnen blijven monitoren!</Alert> : null}
    </> : null}
  </>
}

export default StageTextComponent