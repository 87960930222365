import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'Store/Reducers/reducers'
import User from 'dskcore/@interfaces/User'

// Checks if component can be rendered based on admin login
const AdminSecurity = ({children}: {children: React.ReactElement}) => {
  const user: User | null = useSelector((state: ApplicationState) => state.user)
  
  if (!user) {
    return <Redirect to='/admin' />
  } else {
    return children
  }
}

export default AdminSecurity