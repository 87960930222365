import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'

import { reducers } from './Reducers/reducers'
import { ApplicationState } from './Reducers/reducers'
import { DEFAULT_CENTER } from '../Helpers/MapHelpers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'filter', 'disclaimer'] // only these will be persisted
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const getEmptyAddress = () : StageLocation => ({
  stageId: '',
  markerId: Math.floor(Math.random() * 1000000000).toString(),
  address: '',
  coordinates: DEFAULT_CENTER
})

export const initialStage: Stage = {
  praktijk: '',
  praktijkType: [],
  beschrijving: '',
  voorkeurStudent: 'Geen voorkeur',
  stageduur: '',
  stagedagen: '',
  mail: '',
  contactpersoon: '',
  mailcontactpersoon: '',
  telefoonnummer: '',
  website: '',
  locations: [getEmptyAddress()]
}

/**
 * Set initial values when we create store
 */

export const initialFilter: FilterStageObject = {
  disableFilter: true,
  praktijkType: 'Gemengd',
  voorkeurStudent: 'Geen voorkeur',
  page: 0,
  searchString: undefined
}

const InitialApplicationState: ApplicationState = {
  user: null,
  filter: initialFilter,
  disclaimer: null
}

const store = createStore(
  persistedReducer,
  // @ts-ignore-all
  InitialApplicationState, // @ts-ignore all
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)

export default store