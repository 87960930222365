import { gql } from '@apollo/client'

export const CREATE_STAGE = gql`
  mutation createStage(
    $praktijk: String!,
    $praktijkType: [String]!,
    $beschrijving: String!,
    $voorkeurStudent: String!,
    $locations: [JSONObject]!,
    $stagedagen: String,
    $stageduur: String,
    $mail: String,
    $telefoonnummer: String,
    $website: String,
    $contactpersoon: String,
    $mailcontactpersoon: String,
  ) {
    createStage(
      praktijk: $praktijk
      praktijkType: $praktijkType
      beschrijving: $beschrijving
      voorkeurStudent: $voorkeurStudent
      stagedagen: $stagedagen
      stageduur: $stageduur
      mail: $mail
      telefoonnummer: $telefoonnummer
      website: $website
      contactpersoon: $contactpersoon
      mailcontactpersoon: $mailcontactpersoon
      locations: $locations
    ) {
      _id # in fact you only need to return the _id
      praktijk
      praktijkType
      beschrijving
      voorkeurStudent
      stagedagen
      stageduur
      mail
      telefoonnummer
      website
      contactpersoon
      mailcontactpersoon
      locations {
        stageId
        markerId
        address
        coordinates
      }
    }
  }
`