import User from 'dskcore/@interfaces/User'

import { UserActionTypes } from '../Actions/user-actions-types.d'
import { SET_USER, LOG_OUT } from '../Actions/user-actions'

export const userReducer = (state: User | null = null, action: UserActionTypes): User | null => {
  switch (action.type) {
    case SET_USER:
      return action.payload.user
    case LOG_OUT:
      return null
    default:
      return state
  }
}