import React from 'react'
import { Typography } from '@material-ui/core'

import EmptyState from 'Components/EmptyState'
import {Spinner, CubeOne, CubeTwo } from 'StyledComponents/Loading'

const Loading = ({ text = `Loading ${process.env.REACT_APP_NAME}` }: { text?: string }) => <EmptyState>
  <Spinner className='spinner text-center mt-0 mb-5'>
    <CubeOne></CubeOne>
    <CubeTwo></CubeTwo>
  </Spinner>
  <Typography className='w-300 uppercase' component='h1' variant='h6'>
    {text}
  </Typography>
</EmptyState>

export default Loading