import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  alert: {
    width: 'calc(100% - 30px)'
  }
})

const DisclaimerAlert = ({isModal, checked, handleChange}: {isModal?: boolean, checked?: boolean, handleChange?: any}) => {
  const classes = useStyles()

  return <>
    <Alert severity='error' className={`${classes.font} ${classes.alert}`}>LET OP!{' '}
      {isModal ? 'Door gebruik te maken van de website van VetWise bevestigt u dat u akkoord gaat met de algemene voorwaarden, het privacybeleid van de D.S.K. en het juridische beleid van VetWise.' : 'Door verder te gaan naar de website van VetWise bevestigt u dat u akkoord gaat met de algemene voorwaarden, het privacybeleid van de D.S.K. en het juridische beleid van VetWise.'}
    </Alert>
    <FormControl component='fieldset' className={`mt-3`}>
      {handleChange ? <FormGroup>
        <FormControlLabel
          control={<Checkbox color='primary' checked={checked} onChange={handleChange} name='gilad' />}
          label='Ik ga akkoord met de voorwaarden'
        />
      </FormGroup> : null}
      <FormHelperText>
        Voor de complete documenten kunt u onderstaande link volgen:{' '}
        <a href='https://mijn.dskonline.nl/downloads' target='_blank' rel='noopener noreferrer'>https://mijn.dskonline.nl/downloads</a>
        <br/>
        {`(Mijn D.S.K. -> Bestanden -> Belangrijke documenten D.S.K.)`}
      </FormHelperText>
    </FormControl>
  </>
}

export default DisclaimerAlert
