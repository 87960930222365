const ORSAPIKEY = '5b3ce3597851110001cf624841f2ec4230eb4e7e8bcb1c26a37cbdd2'

function AutocompleteAddress(searchString: string, setSuggestions: Function, setSuggestionsError: Function) {
  const url = `https://api.openrouteservice.org/geocode/search?api_key=${ORSAPIKEY}&text=${searchString}&boundary.country=NLD&layers=address&sources=osm,oa,wof,gn`

  fetch(url)
    .then(res => res.json())
    .then((result) => {
      setSuggestions(result.features)
    }, (error) => {
      setSuggestionsError(error)
    })
}

export default AutocompleteAddress