import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Container, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'

import StageEdit from 'Components/StageEdit'

import { initialStage, getEmptyAddress } from 'Store/store'
import { ApplicationState } from 'Store/Reducers/reducers'
import { GET_STAGE_DETAIL } from 'Apollo/Remote/Stage/GET_STAGE_DETAIL'
import { EDIT_STAGE } from 'Apollo/Remote/Admin/EDIT_STAGE'
import { GET_STAGE_LOCATIONS } from 'Apollo/Remote/Stage/GET_STAGE_LOCATIONS'

const EditStage = () => {
  const [stage, setStage] = React.useState<Stage>(initialStage)
  const [stageLocations, setStageLocations] = React.useState<StageLocation[]>([getEmptyAddress()])
  const [success, setSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<any>('')

  const filter: FilterStageObject = useSelector((state: ApplicationState) => state.filter)

  // @ts-ignore all
  const { stageId } = useParams()
  const { data: stageDetail } = useQuery(GET_STAGE_DETAIL, { variables: { _id: stageId }, errorPolicy: 'all' })

  const [editStage, { data, loading, error: _errorEdit }] = useMutation(EDIT_STAGE, {
    refetchQueries: [{
      query: GET_STAGE_LOCATIONS,
      variables: {
        praktijkType: filter.praktijkType,
        voorkeurStudent: filter.voorkeurStudent,
        disableFilter: filter.disableFilter
      }
    }],
    errorPolicy: 'all'
  })

  React.useEffect(() => {
    if (stageDetail) {
      setStage(stageDetail.stageById)
      if (stageDetail.stageById) {
        setStageLocations(stageDetail.stageById.locations)
      }
    }
  }, [stageDetail])

  React.useEffect(() => {
    if (data?.editStage?._id) {
      const titleElement = document.getElementById('scrollContainer')
      if (titleElement) {
        titleElement.scrollTo(0,0)
      }
      setSuccess(true)
      setError('')
    }
  }, [data])

  React.useEffect(() => {
    if (_errorEdit) {
      setSuccess(false)
      if (_errorEdit.graphQLErrors[0].extensions) {
        const errorsObject = _errorEdit.graphQLErrors[0].extensions.exception
        setError(errorsObject)
      }
    }
  }, [_errorEdit])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    editStage({
      variables: {
        ...stage,
        locations: stageLocations
      }
    })
  }

  return <Container component='main' maxWidth='lg' className='mt-3 pb-5'>
    {(!stage) ? <Typography color='textPrimary'>Stage met id {stageId} bestaat niet</Typography> : null}

    {(stage && stage._id) ? <StageEdit
      stage={stage}
      setStage={setStage}
      stageLocations={stageLocations}
      setStageLocations={setStageLocations}
      onSubmit={onSubmit}
      success={success}
      setSuccess={setSuccess}
      error={error}
      setError={setError}
      isEdit
      loading={loading} /> : null}
  </Container>
}

export default EditStage