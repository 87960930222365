import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'

import Disclaimer from 'dskcore/@types/Disclaimer'
import { ApplicationState } from 'Store/Reducers/reducers'

// Checks if component can be rendered based on disclaimer
const DisclaimerSecurity = ({children}: {children: React.ReactElement}) => {
  const disclaimer: Disclaimer | null = useSelector((state: ApplicationState) => state.disclaimer)
 
  if (disclaimer !== true) {
    // TODO go back to route after accepting disclaimer
    // Record current route
    return <Redirect to='/' />
  } else {
    return children
  }
}

export default DisclaimerSecurity