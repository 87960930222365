import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Stage from 'dskcore/@interfaces/Stage'

import { StyledNavLink } from 'StyledComponents/StageCard'
import StageTextComponent from './StageTextComponent'

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  withBorder: {
    borderBottom: '1px solid #E5E5E5'
  },
  withActiveBackground: {
    backgroundColor: '#E5E5E5'
  },
  praktijk: {
    fontFamily: '"Mulish", sans-serif',
    color: '#3C64B1',
    fontWeight: 'bold',
    fontSize: '20px'
  },
  font: {
    fontFamily: '"Mulish", sans-serif'
  }
})

const StageCard = ({
  stage,
  onHover,
  activeHoverOnMap
}: {
  stage: Stage,
  onHover?: Function,
  activeHoverOnMap?: boolean
}) => {
  const classes = useStyles()
  const [activeCard, setActiveCard] = React.useState(false)

  const hoverProps = onHover && stage ? {
    onMouseEnter: () => {
      onHover(stage._id)
      setActiveCard(true)
    }, onMouseLeave: () => {
      onHover()
      setActiveCard(false)
    }
  } : {}

  const isActiveCard = activeCard === true || activeHoverOnMap === true

  return <StyledNavLink to={`/stage/${stage._id}`} {...hoverProps}>
    <Card className={`${classes.card} ${activeHoverOnMap ? classes.withBorder : null} ${isActiveCard ? classes.withActiveBackground : null}`}>
      <CardActionArea>
        <CardContent>
          <StageTextComponent stage={stage} showDetail={false} />
        </CardContent>
      </CardActionArea>
    </Card>
  </StyledNavLink>
}

export default StageCard