import React from 'react'
import { Redirect } from 'react-router-dom'
// import { Redirect, useParams } from 'react-router-dom'
import { Grid, Typography, makeStyles, Theme} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import InfoIcon from '@material-ui/icons/Info'
import Popover from '@material-ui/core/Popover'

import Snackbar from 'Components/Snackbar'
import RenderInput from 'Components/RenderInput'
import AddressInput from 'Components/AddressInput'

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'
import VoorkeurStudent from 'dskcore/@types/VoorkeurStudent'
import VoorkeurenStudent from 'dskcore/@constants/VoorkeurenStudent'
import PraktijkType from 'dskcore/@types/PraktijkType'
import PraktijkTypes from 'dskcore/@constants/PraktijkTypes'

const StageEditStyles = makeStyles((theme: Theme) => ({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#000',
    letterSpacing: '0.1px',
    lineHeight: '40px'
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 400,
    color: '#000',
    letterSpacing: '0.1px',
    lineHeight: '22.6px'
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'initial',
    height: '40px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold'
  },
  infoIcon: {
    color: '#55357B',
    marginLeft: '5px'
  },
  locatie: {
    display: 'flex',
    flexDirection: 'row'
  },
  popoverText: {
    padding: '16px',
    width: '400px'
  }
}))

const StageEdit = ({stage, setStage, stageLocations, setStageLocations, onSubmit, success, error, setSuccess, setError, isEdit, loading}: {stage: Stage, setStage: Function, stageLocations: StageLocation[], setStageLocations: Function, onSubmit: Function, success: any, error: string, setSuccess: Function, setError: Function, isEdit: boolean, loading: boolean}) => {
  const classes = StageEditStyles()
  const [goBack, setGoBack] = React.useState<boolean>(false)
  const [anchorPopoverEl, setAnchorPopoverEl] = React.useState<HTMLButtonElement | null>(null)
  
  const handleChange = (name: string, value: any): void => {
    setStage({...stage, [name]: value})
  }

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorPopoverEl(event.currentTarget)
  const closePopover = () => setAnchorPopoverEl(null)

  const isOpenPopover = Boolean(anchorPopoverEl)
  const idPopover = isOpenPopover ? 'simple-popover' : undefined

  if (goBack) return <Redirect to='/admin/stages' />

  const fieldHasError = (field: keyof Stage): boolean => !!error[field]
  const getError = (field: keyof Stage): string => error[field]

  const firstError: string = error ? Object.values(error)[0] : ''

  return <>
    <Breadcrumbs aria-label='breadcrumb' className={`${classes.font} mb-2`}>
      <Link color='inherit' href='/admin/stages' onClick={() => setGoBack(true)} className={`${classes.font}`}>
        Stages
      </Link>
      {stage.praktijk ? <Typography color='textPrimary' className='capitalize'>{stage.praktijk}</Typography> : null}
    </Breadcrumbs>

    <Typography className={`${classes.title} mt-3 mb-3`} component='h1' variant='h4'>
      {!isEdit ? 'Nieuwe Stage' : 'Wijzig Stage'}
    </Typography>

    {success && <Snackbar
      variant='success'
      className='mb-4'
      message={`${stage.praktijk} opgeslagen`}
      onClose={() => setSuccess(false)} />}

    {error && <Snackbar
      variant='error'
      className='mb-4'
      message={firstError}
      onClose={() => setError('')} />}

    <form id='stageSubmitForm' onSubmit={(e: React.FormEvent<HTMLFormElement>) => onSubmit(e)}>
      <Grid container spacing={6}>
        <Grid item md={4} sm={12} xs={12}>
          <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
            Algemeen
          </Typography>
          <RenderInput input={{
            type: 'text',
            name: 'praktijk',
            label: 'Naam'
          }}
          value={stage.praktijk}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('praktijk'),
            helperText: fieldHasError('praktijk') && getError('praktijk'),
            autoFocus: true,
            required: true
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'text',
            name: 'beschrijving',
            label: 'Beschrijving'
          }}
          value={stage.beschrijving}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('beschrijving'),
            helperText: fieldHasError('beschrijving') && getError('beschrijving'),
            multiline: true,
            rows: 1,
            rowsMax: 4,
            required: true,
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'checkboxes',
            name: 'praktijkType',
            label: 'Soort'
          }}
          value={stage.praktijkType}
          handleChange={handleChange}
          typeOptions={{
            options: PraktijkTypes.map((praktijkType: PraktijkType) => ({
              value: praktijkType,
              label: praktijkType
            })),
            valid: success ? 1 : 0,
            error: fieldHasError('praktijkType'),
            helperText: fieldHasError('praktijkType') && getError('praktijkType'),
            required: true
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'radio',
            name: 'voorkeurStudent',
            label: 'Opleiding *'
          }}
          value={stage.voorkeurStudent}
          handleChange={handleChange}
          typeOptions={{
            options: VoorkeurenStudent.map((voorkeurStudent: VoorkeurStudent) => ({
              value: voorkeurStudent,
              label: voorkeurStudent
            })),
            valid: success ? 1 : 0,
            error: fieldHasError('voorkeurStudent'),
            helperText: fieldHasError('voorkeurStudent') && getError('voorkeurStudent')
          }} />
        </Grid>
        <Grid item md={4} sm={12} xs={12} className={classes.secondColumn}>
          <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
            Duur
          </Typography>
          <RenderInput input={{
            type: 'text',
            name: 'stageduur',
            label: 'Gewenste stage duur'
          }}
          value={stage.stageduur}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('stageduur'),
            helperText: fieldHasError('stageduur') && getError('stageduur')
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'text',
            name: 'stagedagen',
            label: 'Mogelijke dagen'
          }}
          value={stage.stagedagen}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('stagedagen'),
            helperText: fieldHasError('stagedagen') && getError('stagedagen')
          }} />
          <div className='mb-3'/>
          <div className={classes.locatie}>
            <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
              Locatie
            </Typography>
            <span onClick={openPopover} className={classes.infoIcon}>
              <InfoIcon />
            </span>
          </div>
          <Popover
            id={idPopover}
            open={isOpenPopover}
            anchorEl={anchorPopoverEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}>
            <div className={`${classes.popoverText}`}>
              <Typography className={`${classes.font}`}>Gebruik onderstaande invoervelden om een adres in Nederland op te zoeken. Selecteer een van de suggesties. Indien de suggestie niet klopt, klik dan op de edit knop.</Typography>
            </div>
          </Popover>
          
          <AddressInput
            success={success}
            error={getError('locations')}
            stageLocations={stageLocations}
            setStageLocations={setStageLocations} />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
            Contact
          </Typography>
          <RenderInput input={{
            type: 'text',
            name: 'mail',
            label: 'Mail'
          }}
          value={stage.mail || undefined}
          handleChange={handleChange}
          typeOptions={{
            type: 'email',
            valid: success ? 1 : 0,
            error: fieldHasError('mail'),
            helperText: fieldHasError('mail') && getError('mail')
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'text',
            name: 'website',
            label: 'Website'
          }}
          value={stage.website || undefined}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('website'),
            helperText: fieldHasError('website') && getError('website')
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'text',
            name: 'telefoonnummer',
            label: 'Telefoonnummer'
          }}
          value={stage.telefoonnummer || undefined}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('telefoonnummer'),
            helperText: fieldHasError('telefoonnummer') && getError('telefoonnummer')
          }} />
          <div className='mb-3'/>
          <Typography className={`${classes.sectionTitle} mb-3`} component='h1' variant='h6'>
            Contactpersoon
          </Typography>
          <RenderInput input={{
            type: 'text',
            name: 'contactpersoon',
            label: 'Naam'
          }}
          value={stage.contactpersoon || undefined}
          handleChange={handleChange}
          typeOptions={{
            valid: success ? 1 : 0,
            error: fieldHasError('contactpersoon'),
            helperText: fieldHasError('contactpersoon') && getError('contactpersoon')
          }} />
          <div className='mb-3'/>
          <RenderInput input={{
            type: 'text',
            name: 'mailcontactpersoon',
            label: 'Mail contactpersoon'
          }}
          value={stage.mailcontactpersoon || undefined}
          handleChange={handleChange}
          typeOptions={{
            type: 'email',
            valid: success ? 1 : 0,
            error: fieldHasError('mailcontactpersoon'),
            helperText: fieldHasError('mailcontactpersoon') && getError('mailcontactpersoon')
          }} />
          <div className='mb-3'/>
          <Button
            type='submit'
            id='stageSubmit'
            variant='contained'
            color='primary'
            className={`${classes.submit} mt-2`}
            startIcon={<CheckIcon />}>
            Bevestigen
          </Button>
        </Grid>
      </Grid>
    </form>
  </>
}

export default StageEdit