import styled, {css} from 'styled-components'
import {NavLink, NavLinkProps} from 'react-router-dom'
import {AppBar} from '@material-ui/core'

export const NavbarWrapper = styled.div`
  &&& {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    > * {
      flex-shrink: 0;
    }
    .mobileTabs {
      .MuiTabs-flexContainer {
        justify-content: space-around;
        a:not(.active) {
          color: initial;
        }
        a {
          display: flex;
          flex: 1;
          justify-content: center;
        }
      }
    }
  }
`

interface ContentProps {
  height?: any;
}

export const Content = styled.div`
  height: ${(props: ContentProps) => props.height}px;
  overflow-y: auto;
`

interface NavLinkStyledProps extends NavLinkProps {
  outlined?: number
}

export const NavLinkStyled = styled(NavLink)`
  &&& {
    text-decoration: none;
    ${(props: NavLinkStyledProps) => props.outlined === 1 ? css`
      button {
        background: #fff;
        color: #0069C0 !important;
      }` : css`
        color: #fff;
        button {
          color: #fff;
        }
      `
    }
  }
`

export const Logo = styled.img`
  height: 60px;
`

export const StyledAppBar = styled(AppBar)`
  &&& {
    z-index: 1201;
    box-shadow: none;
    height: 69px;
  }
`