import React from 'react'
import L from 'leaflet'
import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import StageLocation from 'dskcore/@interfaces/StageLocation'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'

import Map from 'Components/Map'
import MapMarker from 'Components/MapMarker'
import {MapLocation} from 'StyledComponents/Map'
import { DEFAULT_CENTER } from 'Helpers/MapHelpers'

import { GET_STAGE_LOCATIONS } from 'Apollo/Remote/Stage/GET_STAGE_LOCATIONS'
import { ApplicationState } from 'Store/Reducers/reducers'

const MapWithMarkers = ({
  hoveredGridStageId,
  setHoveredGridStageId,
  hoveredMapStageId,
  setHoveredMapStageId
}: {
  hoveredGridStageId: string,
  setHoveredGridStageId: Function,
  hoveredMapStageId: string,
  setHoveredMapStageId: Function
}) => {
  const filter: FilterStageObject = useSelector((state: ApplicationState) => state.filter)

  const { error, data } = useQuery(GET_STAGE_LOCATIONS, {
    variables: {
      praktijkType: filter.praktijkType,
      voorkeurStudent: filter.voorkeurStudent,
      disableFilter: filter.disableFilter
    },
    errorPolicy: 'all'
  })

  const [stageLocations, setStageLocations] = React.useState<StageLocation[]>([])

  React.useEffect(() => {
    if (data && data.stageLocations) {
      setStageLocations(data.stageLocations)
    }
  }, [data])

  if (error) return <p>ERROR</p>

  const hasStageLocations = stageLocations && stageLocations.length > 0
  const markerCoordinates: L.LatLngExpression[] = hasStageLocations ? stageLocations.map((stageLocation: StageLocation) => stageLocation.coordinates) : []
  const bounds: L.LatLngBounds = L.latLngBounds(markerCoordinates)

  return <MapLocation>
    <Map center={DEFAULT_CENTER} bounds={bounds} zoom={7.5}>
      <>
        {hasStageLocations ? stageLocations.map((stageLocation: StageLocation) => {
          return <MapMarker
            key={stageLocation.markerId}
            stageLocation={stageLocation}
            hoveredGridStageId={hoveredGridStageId}
            setHoveredGridStageId={setHoveredGridStageId}
            hoveredMapStageId={hoveredMapStageId}
            setHoveredMapStageId={setHoveredMapStageId} />
        }) : null}
      </>
    </Map>
  </MapLocation>
}

export default MapWithMarkers
