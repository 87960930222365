import React from 'react'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles, Theme } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles1 = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export interface SnackbarProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: SnackbarProps) {
  const classes = useStyles1()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return <SnackbarContent
    className={clsx(classes[variant], className)}
    aria-describedby='client-snackbar'
    message={
      <span id='client-snackbar' className={classes.message}>
        <Icon className={clsx(classes.icon, classes.iconVariant)} />
        {message}
      </span>
    }
    action={[
      <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ]}
    {...other}
  />
}

const useStyles2 = makeStyles((theme: Theme) => ({
  margin: {
    margin: theme.spacing(1),
  }
}))

const Snackbar = (props: SnackbarProps) => {
  const classes = useStyles2()

  return <MySnackbarContentWrapper
    onClose={props.onClose}
    variant={props.variant}
    message={props.message}
    className={`${classes.margin} ${props.className}`}
  />
}

export default Snackbar