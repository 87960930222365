import styled, {css} from 'styled-components'
import TextField from '@material-ui/core/TextField'
import {KeyboardDatePicker} from '@material-ui/pickers'

import {ValidationTextFieldProps, ValidationKeyboardDatePickerProps} from '../../@client/ValidationFields.d'

const validationStyles = `
  color: green;
  label {
    color: green;
  }
  fieldset {
    border-color: green;
    border-width: 2px;

    &:hover {
      border-color: red !important;
      border-left-width: 6px;
    }
  }
`

export const ValidationTextField = styled(TextField).attrs({
  InputLabelProps: {
    shrink: true
  }
})`
  &&& {
    margin-bottom: 12px;
    ${(props: ValidationTextFieldProps) => props.valid && css`
      ${validationStyles}
    `}
  }
`

export const ValidationKeyboardDatePicker = styled(KeyboardDatePicker).attrs({
  InputLabelProps: {
    shrink: true
  }
})`
  &&& {
    margin-bottom: 18px;
    ${(props: ValidationKeyboardDatePickerProps) => props.valid && css`
      ${validationStyles}
    `}
  }
`