import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'

import { useQuery } from '@apollo/client'

import Loading from '../Components/Loading'
import StageTextComponent from '../Components/StageTextComponent'

import Stage from 'dskcore/@interfaces/Stage'
import { GET_STAGE_DETAIL } from 'Apollo/Remote/Stage/GET_STAGE_DETAIL'

const useStyles = makeStyles({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
    fontSize: '16px'
  }
})

const StageDetailScreen = () => {
  // @ts-ignore all
  const {stageId} = useParams()

  const { loading, error, data } = useQuery(GET_STAGE_DETAIL, { variables: { _id: stageId }, errorPolicy: 'all' })

  const classes = useStyles()
  const [goBack, setGoBack] = React.useState<boolean>(false)

  if (loading) return <Loading/>
  if (goBack) return <Redirect to='/' />
  
  const stage: Stage = data.stageById

  return <Container component='main' maxWidth='lg' className='pt-5 pt-1 pb-5'>
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <Breadcrumbs aria-label='breadcrumb' className={`${classes.font} mb-4`}>
          <Link color='inherit' href='/' onClick={() => setGoBack(true)} className={`${classes.font}`}>
            Stages
          </Link>
          {!error ? <Typography color='textPrimary' className='capitalize'>{stage.praktijk}</Typography> : null}
        </Breadcrumbs>
          
        {error ? <Typography color='textPrimary'>Stage met id {stageId} bestaat niet</Typography> : null}

        {!error ? <StageTextComponent stage={stage} showDetail /> : null}
      </Grid>
    </Grid>
  </Container>
}

export default StageDetailScreen