import React, {useCallback} from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { InputAdornment, IconButton } from '@material-ui/core'

import Snackbar from 'Components/Snackbar'
import RenderInput from 'Components/RenderInput'
import RedirectRules from 'Helpers/RedirectRules'

import { LOGIN_ADMIN } from 'Apollo/Remote/Admin/LOGIN_ADMIN'
import { setUser } from '../../Store/Actions/user-actions'
import User from 'dskcore/@interfaces/User'

const useStyles = makeStyles((theme: Theme) => ({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  loginForm: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#000',
    letterSpacing: '0.1px',
    lineHeight: '40px',
    textAlign: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'initial',
    height: '40px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold'
  },
  forgotPasswordLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '6px'
  }
}))

interface LoginUser {
  username: string,
  password: string
}

const LoginScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = React.useState(false)
  const [userLogin, setUserLogin] = React.useState<LoginUser>({
    username: '',
    password: ''
  })
  
  const [success, setSuccess] = React.useState<any>(false)
  const [error, setError] = React.useState<String>('')

  const _setUser: Function = useCallback((user: User) => dispatch(setUser(user)), [dispatch])

  const [loginAdmin, { data, error: _errorLogin }] = useMutation(LOGIN_ADMIN, {errorPolicy: 'all'})

  React.useEffect(() => {
    if (_errorLogin) {
      // TODO figure out how to set field level error
      setError(_errorLogin.message)
    }
  }, [_errorLogin])

  React.useEffect(() => {
    if (data?.loginAdmin) {
      _setUser(data.loginAdmin)
    }
  }, [_setUser, data])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    loginAdmin({
      variables: {
        ...userLogin
      }
    })
  }

  const handleChange = (name:string, value: any) => setUserLogin({...userLogin, [name]: value})
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

  const fieldHasError = (field: string) => !!error[field]
  // const formHasError = Object.values(error).some((item: any) => item.length > 0)

  const redirectPath = RedirectRules('Login')
  if (redirectPath)
    return <Redirect to={redirectPath} />

  return <Container component='main' maxWidth='xs' className={classes.loginForm}>
    <CssBaseline />
    <div className={`${classes.paper}`}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component='h1' variant='h5' className={`${classes.font} ${classes.title} pt-4 pb-4`}>
        Log In
      </Typography>

      {success && <Snackbar
        variant='success'
        message={success}
        onClose={() => setSuccess(false)} />}

      {error && <Snackbar
        variant='error'
        message={`Foutmelding: ${error}`}
        onClose={() => setError('')} />}

      <form id='loginform' className={classes.form} onSubmit={onSubmit}>
        <RenderInput input={{
          type:'text',
          name:'username',
          label: 'Gebruikersnaam'
        }}
        value={userLogin.username}
        handleChange={handleChange}
        typeOptions={{
          autoComplete: 'username',
          valid: success ? 1 : 0,
          error: fieldHasError('username') && error['username'],
          helperText: fieldHasError('username') && error['username'],
          autoFocus: true,
          type: 'username',
          required: true
        }} />
        <RenderInput
          input={{
            type:'text',
            name:'password',
            label: 'Wachtwoord'
          }}
          value={userLogin.password}
          handleChange={handleChange}
          typeOptions={{
            autoComplete: 'current-password',
            valid: success ? 1 : 0,
            error: fieldHasError('password'),
            helperText: fieldHasError('password') && error['password'],
            type: showPassword ? 'text' : 'password',
            required: true,
            InputProps: {
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    aria-label='Maak wachtwoord zichtbaar'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }} />
        <Grid container className='text-center'>
          <Grid item xs>
            <Button
              id='loginsubmit'
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}>
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  </Container>
}

export default LoginScreen