import styled from 'styled-components'

const DevelopmentRibbon = styled.div`
  width: 250px;
  background: #e43;
  position: absolute;
  text-align: center;
  line-height: 30px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  color: #f0f0f0;
  z-index: 9999999;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  pointer-events: none;
`

export default DevelopmentRibbon