import { createMuiTheme } from '@material-ui/core/styles'

//  light / main /dark / contrastText
const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#55357B'
    }, 
    secondary: {
      main: '#f3f6fa'
    }
  }
})

export default Theme