import React, {useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Div100vh from 'react-div-100vh'
import { use100vh } from 'react-div-100vh'
import { Link, useRouteMatch } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import { ExitToApp } from '@material-ui/icons'

import * as Styled from 'StyledComponents/Navbar'
import DisclaimerAlert from 'Components/DisclaimerAlert'
import { ApplicationState } from 'Store/Reducers/reducers'
import User from 'dskcore/@interfaces/User'
import { logOut } from 'Store/Actions/user-actions'
import { resetFilter } from 'Store/Actions/filter-actions'

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
    height: '64px'
  },
  logoLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none'
  },
  logo: {
    width: '42px',
    height: '42px',
  },
  title: {
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold',
    color: '#55357B',
    fontSize: '13px',
    lineHeight: '13px',
  },
  footer: {
    boxShadow: 'none'
  },
  toolbarFooter: {
    justifyContent: 'flex-end',
    minHeight: '56px !important'
  },
  modalBody: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    padding: '20px',
    outline: 0
  },
  logoutIcon: {
    fontSize: '32px'
  }
})

const Navbar = ({children}: {children: JSX.Element}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [modalStyle] = React.useState(getModalStyle)
  const [openDisclaimer, setOpenDisclaimer] = React.useState<boolean>(false)
  const user: User | null = useSelector((state: ApplicationState) => state.user)
  // @ts-ignore all
  const maxHeight = use100vh() - 68 - 56
  const height = maxHeight ? maxHeight : null

  const showFooter = useRouteMatch('/')?.isExact !== true

  const _logoutUser: Function = useCallback(() => dispatch(logOut()), [dispatch])
  const _resetFilter: Function = useCallback(() => dispatch(resetFilter()), [dispatch])
  const onClickLogout = () => {
    _logoutUser()
    _resetFilter()
  }

  return <Styled.NavbarWrapper>
    <Div100vh>
      <Styled.StyledAppBar color='secondary' position='sticky'>
        <Container>
          <Toolbar className={classes.toolbar}>
            <Link to='/' className={classes.logoLeftContainer}>
              <img src='/images/logo.png' className={`${classes.logo} mr-2`} alt='' />
              <Typography variant='h6' color='inherit' className={classes.title}>
                VetWise
              </Typography>
            </Link>
            <div>
              {(user && user._id) ? <ExitToApp
                onClick={() => onClickLogout()}
                color='primary'
                className={`${classes.logoutIcon} pointer mr-5`} /> : null}
              <a href='https://www.knmvd.nl/' target='_blank' rel='noopener noreferrer'>
                <img src='/images/knmvd-logo.png' className={classes.logo} alt='' />
              </a>
            </div>
          </Toolbar>
        </Container>
      </Styled.StyledAppBar>
      <Styled.Content id='scrollContainer' height={height}>
        {children}
      </Styled.Content>
      {showFooter ? <AppBar color='secondary' position='relative' className={classes.footer}>
        <Toolbar className={classes.toolbarFooter}>
          <Button
            color='inherit'
            onClick={() => setOpenDisclaimer(true)}>Disclaimer</Button>
        </Toolbar>
      </AppBar> : null}
    </Div100vh>

    <Modal
      open={openDisclaimer}
      onClose={() => setOpenDisclaimer(false)}>

      <div style={modalStyle} className={`${classes.modalBody} sm-modal`}>
        <DisclaimerAlert isModal />
      </div>
    </Modal>
  </Styled.NavbarWrapper>
}

export default Navbar