import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
// @ts-ignore all
import Analytics from 'react-router-ga'
import {version} from '../package.json'

import DisclaimerScreen from 'Containers/DisclaimerScreen'
import StagesScreen from 'Containers/StagesScreen'
import StageDetailScreen from 'Containers/StageDetailScreen'

// Admin
import LoginScreen from 'Containers/Admin/LoginScreen'
import AdminStagesScreen from 'Containers/Admin/AdminStagesScreen'
import AdminCreateStageScreen from 'Containers/Admin/CreateStage'
import AdminEditStagesScreen from 'Containers/Admin/EditStage'

import Navbar from 'Components/Navbar'
import AdminSecurity from 'Components/AdminSecurity'
import DisclaimerSecurity from 'Components/DisclaimerSecurity'
import Theme from 'Theme/Theme'
import DevelopmentRibbon from 'StyledComponents/DevelopmentRibbon'
import withPathName from 'Helpers/WithPathName'

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import Loading from 'Components/Loading'

import store, { persistor } from 'Store/store'

require('dotenv').config() // load .env variables

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://dskvetwiseserver.nl/graphql'

const NoMatch = () => <Redirect to={{pathname: '/'}} />

export const routes = <Switch>
  <Route exact path='/' component={withPathName(DisclaimerScreen, 'Disclaimer')}/>

  {/* Authenticated routes */}
  <DisclaimerSecurity>
    <>
      <Switch>
        <Route exact path='/stages' component={withPathName(StagesScreen, 'Stages')}/>
        <Route exact path='/stage/:stageId' component={withPathName(StageDetailScreen, 'Stage')}/>
        <Route exact path='/admin' component={withPathName(LoginScreen, 'Beheerder Login')}/>

        <AdminSecurity>
          <Switch>
            <Route exact path='/admin/stages' component={withPathName(AdminStagesScreen, 'Stages beheer')}/>
            <Route exact path='/admin/stage' component={withPathName(AdminCreateStageScreen, 'Creëer stage')}/>
            <Route exact path='/admin/stage/:stageId' component={withPathName(AdminEditStagesScreen, 'Wijzig stage')}/>
          </Switch>
        </AdminSecurity>
      </Switch>
    </>
  </DisclaimerSecurity>

  <Route component={NoMatch}/>
</Switch>

// const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
//   }

//   if (networkError) console.log(`[Network error]: ${networkError}`)

//   return forward(operation)
// })

// const authLink = new ApolloLink((operation, forward) => {
//   // operation.setContext(({ headers }) => ({ headers: {
//   //   authorization: Auth.userId(), // however you get your token
//   //   ...headers
//   // }}))
//   return forward(operation)
// })


const App = () => {
  const client = new ApolloClient({
    name: 'react-web-client',
    uri: baseURL,
    connectToDevTools: true,
    cache: new InMemoryCache(),
    // link: from([authLink, errorLink])
    // link: from([authLink])
  })

  if (!client) {
    return <Loading />
  }

  return <ApolloProvider client={client}>
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <BrowserRouter>
            <Navbar>
              <Analytics id={process.env.REACT_APP_GOOGLE_ANALYTICS}>
                {process.env.REACT_APP_DEVELOP_BRANCH === 'true' && <DevelopmentRibbon>
                  Development mode <small>{`v${version}`}</small>
                </DevelopmentRibbon>}
                {routes}
              </Analytics>
            </Navbar>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </ApolloProvider>
}

export default App