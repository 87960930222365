import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'main.css'
import 'utilities.min.css' // bootstrap classes for padding & margin
import 'fontsource-mulish'

const rootEl = document.getElementById('root')

ReactDOM.render(
  <App />,
  rootEl
)
// @ts-ignore-all
if (module.hot) {
  // @ts-ignore-all
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <NextApp />,
      rootEl
    )
  })
}

serviceWorker.unregister()