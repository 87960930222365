import styled from 'styled-components'
import { Container } from '@material-ui/core'

export const EmptyState = styled(Container).attrs((props: any) => props)`
  &&& {
    flex-direction: column;
    text-align: center;
    display: flex;
    height: calc(100vh - 68px - 56px);
    @media (max-width: 991px) {
      max-height: calc(100vh - 68px - 56px);
    }
    justify-content: center;
    margin: 0 auto;
    img {
      margin: 0 auto;
    }
  }
`