import React from 'react'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { useQuery } from '@apollo/client'

import Stage from 'dskcore/@interfaces/Stage'
import StageLocation from 'dskcore/@interfaces/StageLocation'

import StageCard from './StageCard'
import { GET_STAGE_DETAIL } from 'Apollo/Remote/Stage/GET_STAGE_DETAIL'

const activeIcon = L.icon({
  iconUrl: '../images/marker-icon-2x-green.png',
  shadowUrl: '../images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
})

const defaultIcon = L.icon({
  iconUrl: '../images/marker-icon-2x-blue.png',
  shadowUrl: '../images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
})

const MapMarker = ({
  stageLocation,
  hoveredGridStageId,
  setHoveredGridStageId,
  hoveredMapStageId,
  setHoveredMapStageId
}: {
  stageLocation: StageLocation,
  hoveredGridStageId: string,
  setHoveredGridStageId: Function,
  hoveredMapStageId: string,
  setHoveredMapStageId: Function,
}) => {
  // TODO move this function to parent
  const { data } = useQuery(GET_STAGE_DETAIL, { variables: { _id: hoveredMapStageId }, errorPolicy: 'all'})

  const [activeStage, setActiveStage] = React.useState<Stage>()

  React.useEffect(() => {
    if (data && data.stageById) {
      setActiveStage(data.stageById)
    }
  }, [data])

  // if (error) return <p>ERROR</p>

  return <Marker
    key={stageLocation.markerId}
    position={stageLocation.coordinates}
    icon={stageLocation.stageId === hoveredMapStageId || stageLocation.stageId === hoveredGridStageId ? activeIcon : defaultIcon}

    onclick={() => {
      setHoveredGridStageId('')
      setHoveredMapStageId(stageLocation.stageId)
    }}
    onblur={() => setHoveredMapStageId('')}>
    <Popup className='p-0'>
      {activeStage && activeStage._id ? <StageCard stage={activeStage} /> : null}
    </Popup>
  </Marker>
}

export default MapMarker
