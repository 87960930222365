import { gql } from '@apollo/client'

export const GET_STAGES = gql`
  query getStages(
    $page: Int!,
    $praktijkType: String!,
    $voorkeurStudent: String!,
    $disableFilter: Boolean!
  ) {
    stagePaginationFiltering(
      page: $page
      praktijkType: $praktijkType
      voorkeurStudent: $voorkeurStudent
      disableFilter: $disableFilter
    ) {
      count
      stages { 
        _id
        praktijk
        praktijkType
        beschrijving
        voorkeurStudent
        locations {
          markerId
          address
        }
      }
    }
  }
`