import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'
import { SET_FILTER_ACTION, RESET_FILTER_ACTION } from './filter-actions-types.d'

/*
 * Filter actions
 */
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTER = 'RESET_FILTER'

export const setFilter = (filter: FilterStageObject): SET_FILTER_ACTION => ({
  type: SET_FILTER,
  payload: {
    filter
  }
})

export const resetFilter = (): RESET_FILTER_ACTION => ({
  type: RESET_FILTER
})