import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { makeStyles, Theme, List, ListItem, ListItemText, InputAdornment } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import StageLocation from 'dskcore/@interfaces/StageLocation'

import ModalAddressEdit from './ModalAddressEdit'

import {getEmptyAddress} from 'Store/store'
import AutocompleteAddress from 'Helpers/AutocompleteAddress'
import RenderInput from './RenderInput'
import EditIcon from '@material-ui/icons/Edit';
import { DEFAULT_CENTER } from '../Helpers/MapHelpers';

const AddressInputStyles = makeStyles((theme: Theme) => ({
  font: {
    fontFamily: '"Mulish", sans-serif',
    width: '100%',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    textTransform: 'initial',
    height: '40px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold',
    alignSelf: 'flex-end'
  },
  svgIcon: {
    cursor: 'pointer',
    pointerEvents: 'all'
  },
  label: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#373F41',
    lineHeight: '18px'
  }
}))

const AddressInputController = ({ stageLocations, setStageLocations, error, success }: { stageLocations: StageLocation[], setStageLocations: Function, error: string, success: boolean }) => {
  const classes = AddressInputStyles()

  // Modal will open if there is an activeStageLocation
  const [activeStageLocation, setActiveStageLocation] = React.useState<StageLocation>()
  const [openModalAddressEdit, setOpenModalAddressEdit] = React.useState<boolean>(true)

  const addAddress = () => {
    const newStageLocations = [...stageLocations, getEmptyAddress()]
    if (stageLocations.length <= 4) {
      setStageLocations(newStageLocations)
    } else {
      alert('Maximaal 5 adressen kunnen worden toegevoegd.')
    }
  }

  const deleteStageLocation = (index: number) => {
    const newStageLocations = stageLocations.filter((stageLocation: StageLocation, stageIndex: number) => stageIndex !== index)
    setStageLocations(newStageLocations)
  }

  const setAddress = (address: string, locationIndex: number) => {
    const updatedAddress: StageLocation = {
      stageId: '',
      markerId: Math.floor(Math.random() * 1000000000).toString(),
      address,
      coordinates: DEFAULT_CENTER
    }
    const editedStageLocations = stageLocations.map((stageLocation: StageLocation, index: number) => {
      if (index === locationIndex) {
        return updatedAddress
      }
      return stageLocation
    })

    setStageLocations(editedStageLocations)
  }

  const _useSuggestion = (suggestion: any, locationIndex: number) => {
    const newAddress: StageLocation = {
      stageId: '',
      markerId: Math.floor(Math.random() * 1000000000).toString(),
      address: suggestion.properties.label,
      coordinates: [suggestion.geometry.coordinates[1], suggestion.geometry.coordinates[0]]
    }
    const editedStageLocations = stageLocations.map((stageLocation: StageLocation, index: number) => {
      if (index === locationIndex) {
        return newAddress
      }
      return stageLocation
    })

    setStageLocations(editedStageLocations)
  }

  const updateStageLocation = (_stageLocation: StageLocation) => {
    const editedStageLocations = stageLocations.map((mappedStageLocation: StageLocation) => {
      if (mappedStageLocation.markerId === _stageLocation.markerId) {
        return _stageLocation
      }
      return mappedStageLocation
    })

    console.log(editedStageLocations)

    setStageLocations(editedStageLocations)
    setActiveStageLocation(undefined) // Closes modal
  }

  return <>
    {activeStageLocation && activeStageLocation.markerId && activeStageLocation.markerId.length > 0 ? <ModalAddressEdit 
      stageLocation={activeStageLocation}
      updateStageLocation={updateStageLocation}
      openModalAddressEdit={openModalAddressEdit}
      setOpenModalAddressEdit={setOpenModalAddressEdit} /> : null}
    
    {stageLocations.map((stageLocation: StageLocation, index: number) => {
      return <AddressInput
        key={index}
        index={index}
        error={error}
        success={success}
        stageLocation={stageLocation}
        setAddress={setAddress}
        _useSuggestion={_useSuggestion}
        setActiveStageLocation={setActiveStageLocation}
        setOpenModalAddressEdit={setOpenModalAddressEdit}
        deleteStageLocation={deleteStageLocation}
        showDelete={(stageLocations && stageLocations.length !== 0) && index !== 0}
        showMB={stageLocations.length !== index + 1} />
    })}
    {stageLocations.length < 5 ? <Button
      id='nieuwAdres'
      onClick={() => addAddress()}
      variant='outlined'
      color='primary'
      className={classes.submit}
      startIcon={<AddIcon />}>
      Nieuw adres
    </Button> : null}
  </>
}

const AddressInput = ({
  index,
  error,
  success,
  stageLocation,
  setAddress,
  _useSuggestion,
  setActiveStageLocation,
  setOpenModalAddressEdit,
  deleteStageLocation,
  showDelete,
  showMB
}: {
  index: number,
  error: string,
  success: boolean,
  stageLocation: StageLocation,
  setAddress: Function,
  _useSuggestion: Function,
  setActiveStageLocation: Function,
  setOpenModalAddressEdit: Function,
  deleteStageLocation: Function,
  showDelete: boolean,
  showMB: boolean
}) => {
  const classes = AddressInputStyles()
  // const markerId = stageLocation.markerId

  const [cursorPosition, setCursorPosition] = React.useState<number>()
  const [suggestions, setSuggestions] = React.useState<any[]>()

  // const [isSuggestionUsed, setSuggestionUsed] = React.useState<boolean>(false)
  // const [isSuggestionsError, setSuggestionError] = React.useState<boolean>(false)

  const handleChange = useDebouncedCallback((name: string, searchString: string) => {
    AutocompleteAddress(searchString, setSuggestions, () => console.error('Iets ging fout met AutocompleteAddress'))
  }, 250)

  const _setAddress = (name: string, searchString: string, selectionStart: number) => {
    setCursorPosition(selectionStart)
    setAddress(searchString, index)
    handleChange.callback(name, searchString)
  }

  return <div key={stageLocation.markerId}>
    <RenderInput input={{
      type: 'text',
      name: `address${index}`,
      label: `Adres ${index + 1}`
    }}
    value={stageLocation.address}
    handleChange={_setAddress}
    typeOptions={{
      placeholder: index === 0 ? 'Bijv: Evertsenstraat 7, Nijmegen' : '',
      valid: success ? 1 : 0,
      error: error && stageLocation.coordinates[0] === 0 && stageLocation.coordinates[1] === 0 ? true : false,
      helperText: error && stageLocation.coordinates[0] === 0 && stageLocation.coordinates[1] === 0 ? error : '',
      autoFocus: true,
      required: true,
      onFocus: (e) => {
        e.target.selectionStart = cursorPosition
      },
      InputProps: {
        endAdornment: <React.Fragment>
          <InputAdornment position='start'>
            <EditIcon color='primary' className={`${classes.svgIcon}`} onClick={() => {
              setActiveStageLocation(stageLocation)
              setOpenModalAddressEdit(true)
            }} />
          </InputAdornment>
          {showDelete ? <InputAdornment position='end'>
            <DeleteIcon color='primary' className={classes.svgIcon} onClick={() => {
              deleteStageLocation(index)
              setSuggestions([])
              // setSuggestionError(false)
            }} />
          </InputAdornment> : null}
        </React.Fragment>
      }
    }} />

    {suggestions && suggestions.length > 0 ? <List>
      {suggestions.map((suggestion) => {
        return <ListItem key={suggestion.properties.id} className='pointer' onClick={() => {
          _useSuggestion(suggestion, index)
          setSuggestions([])
          // setSuggestionUsed(true)
          // setSuggestionError(false)
        }}>
          <ListItemText primary={suggestion.properties.label} />
        </ListItem>})}
    </List> : null}

    {showMB ? <div className='mb-3'/> : null}
  </div>
}

export default AddressInputController