import React from 'react'
import L from 'leaflet'
import { Marker, Popup } from 'react-leaflet'

import StageLocation from 'dskcore/@interfaces/StageLocation'

import Map from 'Components/Map'
import {MapLocation} from 'StyledComponents/Map'

const defaultIcon = L.icon({
  iconUrl: '/images/marker-icon-2x-blue.png',
  shadowUrl: '/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
})

const MapSelectCoordinates = ({
  stageLocation,
  updateCoordinates
}: {
  stageLocation: StageLocation,
  updateCoordinates: Function
}) => {
  const [coordinatesMarker, setCoordinatesMarker] = React.useState<L.LatLngExpression>(stageLocation.coordinates)

  return <MapLocation isAdmin>
    <Map center={stageLocation.coordinates} zoom={7.5} onMapClick={(event: L.LeafletMouseEvent) => {
      setCoordinatesMarker(event.latlng)
      updateCoordinates(event.latlng)
    }}>
      <Marker
        position={coordinatesMarker}
        icon={defaultIcon}>
        <Popup className='p-0'>
          {stageLocation.address}
        </Popup>
      </Marker>
    </Map>
  </MapLocation>
}

export default MapSelectCoordinates
