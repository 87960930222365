import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import MapIcon from '@material-ui/icons/Map'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InfiniteScroll from 'react-infinite-scroll-component'
import { use100vh } from 'react-div-100vh'

import Stage from 'dskcore/@interfaces/Stage'
import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'
import PraktijkType from 'dskcore/@types/PraktijkType'
import VoorkeurStudent from 'dskcore/@types/VoorkeurStudent'
import VoorkeurenStudent from 'dskcore/@constants/VoorkeurenStudent'
import PraktijkTypes from 'dskcore/@constants/PraktijkTypes'

import MapWithMarkers from 'Components/MapWithMarkers'
import StageCard from 'Components/StageCard'
import Loading from 'Components/Loading'
import useWindowSize from 'Helpers/useWindowSize'

import { GET_STAGES } from 'Apollo/Remote/Stage/GET_STAGES'
import { useQuery } from '@apollo/client'
import { ApplicationState } from 'Store/Reducers/reducers'
import { setFilter } from 'Store/Actions/filter-actions'
import { Content } from '../StyledComponents/Navbar';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: '16px'
  },
  title: {
    borderBottom: '1px solid #E5E5E5',
    fontWeight: 'bold',
    fontSize: '32px',
    color: '#373F41',
    letterSpacing: '0.1px',
    lineHeight: '40px'
  },
  listStages: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  font: {
    fontFamily: '"Mulish", sans-serif'
  },
  formControl: {
    minWidth: '120px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    paddingRight: '10px',
    paddingTop: '15px',
    width: '70px',
  },
  disableFilterLabel: {
    paddingTop: '16px',
    paddingRight: '10px',
    minWidth: '90px',
    marginRight: 0
  },
  select: {
    border: '1px solid #D0D0D0',
    padding: '10px 15px',
    boxShadow: 'none'
  },
  resultActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  filtersContainer: {
    padding: '15px 0',
    display: 'flex',
  },
  toggleButton: {
    textTransform: 'initial',
    height: '32px',
    fontFamily: '"Mulish", sans-serif',
    fontWeight: 'bold',
    color: '#55357B'
  },
  toggleButtonActive: {
    background: '#55357B !important',
    color: '#fff'
  }
})

const StagesScreen = () => {
  const classes = useStyles()
  const size = useWindowSize()
  const dispatch = useDispatch()

  // @ts-ignore all
  const maxHeight = use100vh() - 68 - 56
  const height = maxHeight ? maxHeight : null

  const [isMapView, toggleMapView] = React.useState<boolean>(true)
  const [hoveredGridStageId, setHoveredGridStageId] = React.useState<string>('') // id of Match that is currently hovered over in left-grid
  const [hoveredMapStageId, setHoveredMapStageId] = React.useState<string>('') // id of Match that is currently hovered over on map

  const filter: FilterStageObject = useSelector((state: ApplicationState) => state.filter)
  const _setFilter: Function = useCallback((filter: FilterStageObject) => dispatch(setFilter(filter)), [dispatch])
  const [page, setPage] = React.useState<number>(0)

  const { loading, error, data } = useQuery(GET_STAGES, {
    variables: {
      page,
      praktijkType: filter.praktijkType,
      voorkeurStudent: filter.voorkeurStudent,
      disableFilter: filter.disableFilter
    },
    errorPolicy: 'all'
  })

  React.useEffect(() => {
    if (size?.width) {
      // @ts-ignore all
      toggleMapView(size.width < 991 ? false : true)
    }
  }, [size])

  const [stages, setStages] = React.useState<Stage[]>([])
  const [stageCount, setStageCount] = React.useState<number>(0)

  React.useEffect(() => {
    if (data && data.stagePaginationFiltering) {
      setStages((stages) => [...stages, ...data.stagePaginationFiltering.stages])
      setStageCount(data.stagePaginationFiltering.count)
    }
  }, [data])

  if (error) return <p>ERROR</p>

  const updatedisableFilter = (disableFilter: boolean) => {
    setStages([])
    _setFilter({
      ...filter,
      disableFilter
    })
    setPage(0)
  }

  const updatePraktijkType = (praktijkType: PraktijkType) => {
    setStages([])
    _setFilter({
      ...filter,
      disableFilter: false,
      praktijkType
    })
    setPage(0)
  }

  const updateVoorkeurStudent = (voorkeurStudent: VoorkeurStudent) => {
    setStages([])
    _setFilter({
      ...filter,
      disableFilter: false,
      voorkeurStudent
    })
    setPage(0)
  }

  const loadMore = () => {
    setPage(page + 1)
  }

  return <Container component='main' maxWidth='lg' className={classes.container}>
    <Grid container>
      <Grid item md={6} xs={12} className={`${classes.listStages} ${isMapView ? 'maxHeight' : ''} pr-0`}>
        <Content id='stageScrollContainer' className={isMapView ? 'hidden-xs' : ''} height={height}>
          <div className={`${classes.filtersContainer} pl-3`}>
            <Grid container spacing={3} className='mr-0'>
              <FormControl className={`${classes.formControl}`}>
                <FormGroup className='mb-0 pl-3'>
                  <FormControlLabel
                    control={<Checkbox
                      color='primary'
                      checked={filter.disableFilter}
                      onChange={() => updatedisableFilter(!filter.disableFilter)}
                      name='gilad' />}
                    className={`${classes.disableFilterLabel} ${classes.font}`}
                    label='Alle stages'
                  />
                </FormGroup>
              </FormControl>
              <Grid item sm={4} xs={12}>
                <FormControl className={classes.formControl} disabled={filter.disableFilter === true}>
                  <FormLabel htmlFor='praktijkType' className={`${classes.label} ${classes.font} labelWidthXs`}>Soort</FormLabel>
                  
                  <NativeSelect
                    className={classes.select}
                    value={filter.praktijkType}
                    onChange={(event: any) => updatePraktijkType(event.target.value)}
                    inputProps={{
                      name: 'praktijkType',
                      id: 'praktijkType',
                    }}>
                    {PraktijkTypes.map((_praktijkType: PraktijkType) => <option key={_praktijkType} value={_praktijkType}>{_praktijkType}</option>)}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl className={`${classes.formControl}`} disabled={filter.disableFilter === true}>
                  <FormLabel htmlFor='voorkeurStudent' className={`${classes.label} ${classes.font}`}>Opleiding</FormLabel>
                  <NativeSelect
                    className={classes.select}
                    value={filter.voorkeurStudent}
                    onChange={(event: any) => updateVoorkeurStudent(event.target.value)}
                    inputProps={{
                      name: 'voorkeurStudent',
                      id: 'voorkeurStudent',
                    }}>
                    {VoorkeurenStudent.map((_voorkeurStudent: VoorkeurStudent) => <option key={_voorkeurStudent} value={_voorkeurStudent}>{_voorkeurStudent}</option>)}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <Typography variant='h4' className={`${classes.font} ${classes.title} pt-4 pl-3 pb-4`}>
            {/*
              @ts-ignore all */}
            {stageCount > 0 ? `${stageCount} ${stageCount === 1 ? 'resultaat' : 'resultaten'}` : 'Geen resultaten'} voor jouw {size?.width > 991 ? <br/> : null} geselecteerde criteria
          </Typography>

          <div className={`${classes.resultActionsContainer} pr-4`}>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<MapIcon />}
              className={`${classes.toggleButton} ${isMapView ? classes.toggleButtonActive : ''} hidden-xs-inverse mt-3 mb-3`}
              onClick={() => toggleMapView(!isMapView)}>
              Kaart
            </Button>
          </div>

          <div className={isMapView ? 'hidden-xs' : ''}>
            {loading === true ? <Loading/> : <InfiniteScroll
              scrollableTarget='stageScrollContainer'
              dataLength={stageCount} //This is important field to render the next data
              next={loadMore}
              hasMore={stages.length < stageCount}
              loader={<Loading/>}
              endMessage={
                <p className={classes.font} style={{ textAlign: 'center' }}>
                  <b>Einde van de resultaten</b>
                </p>
              }>
              {stages.map((stage: Stage) => <StageCard
                key={stage._id}
                stage={stage}
                onHover={() => {
                  setHoveredMapStageId('')
                  setHoveredGridStageId(stage._id)
                }}
                activeHoverOnMap={stage._id === hoveredMapStageId} /> )}
            </InfiniteScroll>}
          </div>
        </Content>
      </Grid>
      <Grid item md={6} xs={12} className={`${!isMapView ? 'hidden-xs' : ''} pl-0 pb-0`}>
        <MapWithMarkers
          hoveredGridStageId={hoveredGridStageId}
          setHoveredGridStageId={setHoveredGridStageId}
          hoveredMapStageId={hoveredMapStageId}
          setHoveredMapStageId={setHoveredMapStageId} />
      </Grid>
    </Grid>
  </Container>
}

export default StagesScreen