import Disclaimer from 'dskcore/@types/Disclaimer'
import { SET_DISCLAIMER } from '../Actions/disclaimer-actions'
import { DisclaimerActionTypes } from '../Actions/disclaimer-actions-types.d'

export const disclaimerReducer = (state: Disclaimer = null, action: DisclaimerActionTypes): Disclaimer => {
  switch (action.type) {
    case SET_DISCLAIMER:
      return action.payload.disclaimer
    default:
      return state
  }
}