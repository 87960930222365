import styled, {css} from 'styled-components'
import Div100vh from 'react-div-100vh'
import { Grid, FormControl, Typography, FormGroup, MobileStepper } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

interface RoleSelectProps {
  active: boolean
}

export const RoleSelect = styled.div`
  position: relative;
  svg:first-child {
    width: 150px;
    height: 150px;
    margin: 20px 20px 10px 20px;
    padding: 20px;
    border-radius: 4px;
    color: black !important;
    ${(props: RoleSelectProps) => props.active === true ? css`
      border: 2px solid #2196F3;
    ` : css`
      border: 2px solid #E5E5E5;
    `}
  }
`

export const CheckIcon = styled(CheckCircleIcon)`
  position: absolute;
  margin-top: 157px;
  margin-left: -33px;
  border: 3px solid #2296f3;
  border-radius: 50%;
  color: #2296f3;
  background: white;
`

export const LeftGrid = styled(Grid)`
  &&& {
    @media (max-width: 991px) {
      display: none;
    }
    background-image: url("/images/homepage_cover_dark.jpg") !important;
    background-blend-mode: darken;
    background-color: rgba(0,0,0,0.5);
    background-size: cover;
    * > span {
      color: white;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .MuiStepper-vertical {
      background: none;
      width: 250px;
    }
  }
`

export const RightGrid = styled(Grid)`
`

export const EditContainer = styled(Div100vh).attrs({
  style: { height: 'calc(100rvh - 230px)' }
})`
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
`

export const BottomNext = styled.div`
  @media (max-width: 991px) {
    display: none !important;
  }
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`

export const StyledMobileStepper = styled(MobileStepper)`
  &&& {
    @media (min-width: 991px) {
      display: none !important;
    }
    padding-top: 16px;
    padding-bottom: 16px;
  }
`

export const StepLabel = styled.div`
  border: 1px solid black;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-right: 8px;
`

export const MapLocation = styled.div`
  > div {
    width: 100%;
    height: 336px;
  }
`

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

interface LabelProps {
  valid?: number;
  error?: number;
}

export const Label = styled(Typography)`
  &&& {
    ${(props: LabelProps) => props.error === 1 && css`
      color: red;
    `}
    ${(props: LabelProps) => props.valid === 1 && css`
      color: green;
    `}
  }
`

export const FormControlStyled = styled(FormControl).attrs({
  component: 'fieldset'
})`
  &&& {
    display: flex;
    flex: 1;
  }
`

export const DetailOption = styled(FormGroup)`
  label {
    margin: 0;
    width: 200px;
    justify-content: space-between;
  }
`