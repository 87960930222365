import styled from 'styled-components'
import {Map} from 'react-leaflet'

// interface MapProps {
//   isAdmin: boolean;
// }

export const MapLocation = styled(Map)<any>`
  width: 100%;
  height: ${props => props.isAdmin ? '400px' : 'calc(100vh - 68px - 56px)'};

  @media (max-width: 960px) {
    height: 400px;
  }
`