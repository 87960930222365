import { useSelector } from 'react-redux'
import User from 'dskcore/@interfaces/User'
import { ApplicationState } from 'Store/Reducers/reducers'

const isAdmin = (user: User | null) => user && user.isAdmin === true

// Dictionary of redirection rules per route
function RedirectRules(pathname: string) {
  const user: User | null = useSelector((state: ApplicationState) => state.user)
  let result = ''
  switch (pathname) {
    case 'Login':
      if (user) {
        result = '/admin/stages'
      }
      
      break
    case 'Admin':
      if (!isAdmin(user)) {
        result = '/'
      }
      break
    default:
      break
  }
  return result
}

export default RedirectRules