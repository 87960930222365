import FilterStageObject from 'dskcore/@interfaces/FilterStageObject'
import { FilterActionTypes } from '../Actions/filter-actions-types.d'
import { SET_FILTER, RESET_FILTER } from '../Actions/filter-actions'

const initialFilter: FilterStageObject = {
  disableFilter: true,
  praktijkType: 'Gemengd',
  voorkeurStudent: 'Geen voorkeur',
  page: 0,
  searchString: undefined
}

export const filterReducer = (state: FilterStageObject = initialFilter, action: FilterActionTypes): FilterStageObject => {
  switch (action.type) {
    case SET_FILTER:
      return action.payload.filter
    case RESET_FILTER:
      return initialFilter
    default:
      return state
  }
}