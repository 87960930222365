import { gql } from '@apollo/client'

export const GET_STAGE_DETAIL = gql`
  query getStageDetail($_id: MongoID!) {
    stageById(_id: $_id) {
      _id
      praktijk
      praktijkType
      beschrijving
      voorkeurStudent
      stageduur
      stagedagen
      contactpersoon
      mail
      mailcontactpersoon
      telefoonnummer
      locations {
        stageId
        markerId
        address
        coordinates
      }
      website
      updatedAt
      createdAt
    }
  }
`